import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import CrossIcon from '@atlaskit/icon/glyph/cross';
import { IconButton } from '@atlaskit/button/new';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { Box } from '@atlaskit/primitives';
import Modal from '@atlaskit/modal-dialog';

const i18n = defineMessages({
	closeModal: {
		id: 'change-edition.elevate-automation.video-modal.close-modal.label',
		description: 'Label to describe close modal button for closing the video modal',
		defaultMessage: 'Close Modal',
	},
});

type ExploreAutomationModalProps = {
	onClose: () => void;
};

export const ExploreAutomationModal = ({ onClose }: ExploreAutomationModalProps) => {
	const { formatMessage } = useIntl();
	return (
		<Modal
			onClose={onClose}
			// To ensure that the close button isn't in focus when the modal is opened, disable auto focus on the modal
			/* eslint-disable-next-line jsx-a11y/no-autofocus */
			autoFocus={false}
			width="large"
			height="474px"
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
			<Box style={{ position: 'relative', height: '100%' }}>
				<Box
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						position: 'absolute',
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						top: token('space.0', '0px'),
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						right: token('space.negative.400', '-32px'),
					}}
				>
					<IconButton
						appearance="subtle"
						onClick={onClose}
						label={formatMessage(i18n.closeModal)}
						// leave label empty for CrossIcon on purpose because label is provided in the line above
						// the icon color should be white for both light and dark mode, so token() is not used here
						/* eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage */
						icon={() => <CrossIcon label="" primaryColor={N0} />}
					/>
				</Box>

				<iframe
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						width: '100%',
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						height: '100%',
					}}
					src="https://www.youtube.com/embed/w47zgnTpenY?si=Li1GE4Zl7ZVNhIZ8"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
					title="Explore Confluence Automation"
				/>
			</Box>
		</Modal>
	);
};
