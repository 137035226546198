import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useSessionData } from '@confluence/session-data';

import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';

import { usePersonalizedAutomationUpsellDismissState } from './usePersonalizedAutomationUpsellDismissState';

export enum Cohort {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	EXPERIMENT = 'experiment',
}

export enum UpsellType {
	MINI_SECTION_MESSAGE = 'mini-section-message',
	SECTION_MESSAGE = 'section-message',
}

const FEATURE_FLAG = 'cc_editions_personalized_upsell_for_automation_exo';
const COHORT = 'cohort';

type HookProps = {
	type?: UpsellType;
	isSpaceAdmin?: boolean;
};

const getFlagValue = () => {
	return FeatureGates.getExperimentValue<string>(FEATURE_FLAG, COHORT, Cohort.NOT_ENROLLED);
};

export const useIsPersonalizedAutomationUpsellEnabled = ({
	type = UpsellType.SECTION_MESSAGE,
	isSpaceAdmin = true,
}: HookProps = {}): {
	shouldRenderPersonalizedAutomationUpsell: boolean;
	dismissPersonalizedAutomationUpsell: () => void;
} => {
	const { edition } = useSessionData();
	const { dismiss: dismissPersonalizedAutomationUpsell, isDismissed } =
		usePersonalizedAutomationUpsellDismissState(type);

	if (isDismissed) {
		return {
			shouldRenderPersonalizedAutomationUpsell: false,
			dismissPersonalizedAutomationUpsell,
		};
	}

	// For SECTION_MESSAGE, it is used for label creation, and we dont need to check space admin for label creation
	const isLabelCreationUpsellEnabled = edition !== ConfluenceEdition.PREMIUM;
	// For MINI_SECTION_MESSAGE, it is used for archive and we need to check space admin
	const isArchiveUpsellEnabled = edition === ConfluenceEdition.STANDARD && isSpaceAdmin;

	const isUpsellEnabled =
		type === UpsellType.MINI_SECTION_MESSAGE
			? isArchiveUpsellEnabled
			: isLabelCreationUpsellEnabled;
	// Only assert feature flag when initial eligibility checks have passed
	const shouldRenderPersonalizedAutomationUpsell = isUpsellEnabled
		? getFlagValue() === Cohort.EXPERIMENT
		: false;

	return {
		shouldRenderPersonalizedAutomationUpsell,
		dismissPersonalizedAutomationUpsell,
	};
};
