import { useCallback, useState } from 'react';

import { keys, confluenceLocalStorageInstance as localStorage } from '@confluence/storage-manager';

import { UpsellType } from './useIsPersonalizedAutomationUpsellEnabled';

export const TWENTY_EIGHT_DAYS_IN_SECONDS = 60 * 60 * 24 * 28;

const {
	AUTOMATION_UPSELL_FROM_LABEL_ADDITION: automationUpsellFromLabelAddition,
	AUTOMATION_UPSELL_FROM_ARCHIVE: automationUpsellFromArchive,
} = keys;

export const usePersonalizedAutomationUpsellDismissState = (type = UpsellType.SECTION_MESSAGE) => {
	const storageKey =
		type === UpsellType.SECTION_MESSAGE
			? automationUpsellFromLabelAddition
			: automationUpsellFromArchive;

	const [isDismissed, setIsDismissed] = useState<boolean | undefined>(
		localStorage?.getItemAsBoolean(storageKey),
	);

	const dismiss = useCallback(() => {
		localStorage?.setItem(storageKey, true, TWENTY_EIGHT_DAYS_IN_SECONDS);
		setIsDismissed(true);
	}, [storageKey, setIsDismissed]);

	return { dismiss, isDismissed };
};
