import React, { type FC, type ReactChild, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { ModalTransition } from '@atlaskit/modal-dialog';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Tooltip from '@atlaskit/tooltip';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';

import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';
import { SectionMessage } from '../UpsellLooknFeel/SectionMessage';
import type { LinkProps } from '../SectionMessage/ChangeEditionSectionMessageButtonsWrapper';
import { MiniSectionMessage } from '../UpsellLooknFeel/MiniSectionMessage';

import { ExploreAutomationModal } from './ExploreAutomationModal';
import { UpsellType } from './useIsPersonalizedAutomationUpsellEnabled';

const i18n = defineMessages({
	modalContent: {
		id: 'change-edition.in-context-automation-upsell.modal-content',
		defaultMessage:
			'<b>Let us archive old pages for you.</b> Set a rule to automatically archive outdated pages after a certain period of time.',
		description: 'Regular message in body',
	},
	sectionMessageBold: {
		id: 'change-edition.in-context-automation-upsell.section-message-bold',
		defaultMessage: 'Simplify the way you add labels with automation.',
		description: 'Highlighted message in body',
	},
	sectionMessageText: {
		id: 'change-edition.in-context-automation-upsell.section-message-text',
		defaultMessage: 'Set a rule to automatically add labels to pages and attachments.',
		description: 'Regular message in body',
	},
	exploreAutomation: {
		id: 'change-edition.in-context-automation-upsell.explore-automation',
		defaultMessage: 'Explore automation',
		description: 'Secondary CTA button text',
	},
	dismissButtonLabel: {
		id: 'change-edition.in-context-automation-upsell.dismiss-button-label',
		defaultMessage: 'Not now',
		description: 'Button hover over text',
	},
});

export type onDismissProps = {
	unmountAutomationUpsell: boolean;
};

type PersonalizedAutomationUpsellComponentProps = {
	type: UpsellType;
	onDismiss: ({ unmountAutomationUpsell }: onDismissProps) => void;
};

const dismissButtonStyles = xcss({
	cursor: 'pointer',
	position: 'absolute',
	right: token('space.300', '24px'),
	top: token('space.200', '16px'),
});

const dismissButton = (dismissButtonLabel: string, onDismiss: () => void) => (
	<Box
		xcss={dismissButtonStyles}
		role="button"
		tabIndex={0}
		testId="dismiss-button-wrapper"
		onClick={onDismiss}
	>
		<Tooltip content={dismissButtonLabel}>
			{(tooltipProps) => (
				<Button
					appearance="subtle"
					iconBefore={<EditorCloseIcon label={dismissButtonLabel} />}
					spacing="none"
					testId="dismiss-button"
					{...tooltipProps}
				/>
			)}
		</Tooltip>
	</Box>
);

const buildBody = (
	boltText: ReactChild,
	regularText: ReactChild,
	dismissButtonLabel: string,
	onDismiss: () => void,
) => (
	<>
		{/* Manually injecting a space between two sentences */}
		<Text weight="bold">{`${boltText} `}</Text>
		<Text>{regularText}</Text>
		{dismissButton(dismissButtonLabel, onDismiss)}
	</>
);

const SUFFIX_TRY_IT_FREE_BUTTON = 'TryItFreeButton';
const SUFFIX_DISMISS_BUTTON = 'DismissButton';
const SUFFIX_EXPLORE_AUTOMATION_BUTTON = 'ExploreAutomation';

const ARCHIVE_AUTOMATION_UPSELL_SOURCE = 'archiveModalAutomationUpsell';
const LABEL_CREATION_AUTOMATION_UPSELL_SOURCE = 'labelCreationAutomationUpsell';

const LEARN_MORE_LINK: Partial<Omit<LinkProps, 'actionSubjectId'>> = {
	messageDescriptor: i18n.exploreAutomation,
	icon: <VidPlayIcon size="small" label="Explore automation" />,
	href: '#', // ensure the learn more button doesn't open a new tab
};

const analyticsMapper: Record<UpsellType, string> = {
	[UpsellType.SECTION_MESSAGE]: LABEL_CREATION_AUTOMATION_UPSELL_SOURCE,
	[UpsellType.MINI_SECTION_MESSAGE]: ARCHIVE_AUTOMATION_UPSELL_SOURCE,
};

const PersonalizedAutomationUpsellComponent: FC<PersonalizedAutomationUpsellComponentProps> = ({
	type = UpsellType.SECTION_MESSAGE,
	onDismiss = () => {},
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onDismissButtonClick = useCallback(
		({ unmountAutomationUpsell }: onDismissProps) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: `${analyticsMapper[type]}${SUFFIX_DISMISS_BUTTON}`,
					source: analyticsMapper[type],
				},
			}).fire();
			onDismiss({ unmountAutomationUpsell });
		},
		[createAnalyticsEvent, onDismiss, type],
	);

	const [isExploreAutomationModalOpen, setIsExploreAutomationModalOpen] = useState(false);
	const openExploreAutomationModal = useCallback(() => setIsExploreAutomationModalOpen(true), []);
	const closeExploreAutomationModal = useCallback(() => {
		setIsExploreAutomationModalOpen(false);
	}, []);

	return (
		<>
			{type === UpsellType.MINI_SECTION_MESSAGE && (
				<MiniSectionMessage
					messageContent={{
						...i18n.modalContent,
						values: {
							b: (chunks) => <b>{chunks}</b>,
						},
					}}
					targetEdition={ConfluenceEdition.PREMIUM}
					dismissButton={dismissButton(formatMessage(i18n.dismissButtonLabel), () =>
						onDismissButtonClick({ unmountAutomationUpsell: true }),
					)}
					learnMoreLink={{
						...LEARN_MORE_LINK,
						appearance: 'subtle-link',
						onClick: () => {
							openExploreAutomationModal();
							onDismiss({ unmountAutomationUpsell: false });
						},
					}}
					onUpgradeButtonClick={() => {
						onDismiss({ unmountAutomationUpsell: false });
					}}
					source={ARCHIVE_AUTOMATION_UPSELL_SOURCE}
					upgradeEditionActionSubjectId={`${ARCHIVE_AUTOMATION_UPSELL_SOURCE}${SUFFIX_TRY_IT_FREE_BUTTON}`}
				/>
			)}

			{type === UpsellType.SECTION_MESSAGE && (
				<SectionMessage
					body={buildBody(
						formatMessage(i18n.sectionMessageBold),
						formatMessage(i18n.sectionMessageText),
						formatMessage(i18n.dismissButtonLabel),
						() => onDismissButtonClick({ unmountAutomationUpsell: true }),
					)}
					learnMoreLink={{
						...LEARN_MORE_LINK,
						actionSubjectId: `${LABEL_CREATION_AUTOMATION_UPSELL_SOURCE}${SUFFIX_EXPLORE_AUTOMATION_BUTTON}`,
						onClick: () => {
							openExploreAutomationModal();
							onDismiss({ unmountAutomationUpsell: false });
						},
					}}
					onUpgradeButtonClick={() => onDismiss({ unmountAutomationUpsell: false })}
					targetEdition={ConfluenceEdition.PREMIUM}
					source={LABEL_CREATION_AUTOMATION_UPSELL_SOURCE}
					upgradeEditionActionSubjectId={`${LABEL_CREATION_AUTOMATION_UPSELL_SOURCE}${SUFFIX_TRY_IT_FREE_BUTTON}`}
				/>
			)}

			<ModalTransition>
				{isExploreAutomationModalOpen && (
					<ExploreAutomationModal onClose={closeExploreAutomationModal} />
				)}
			</ModalTransition>
		</>
	);
};

export const PersonalizedAutomationUpsell = withErrorBoundary({
	attribution: Attribution.EDITIONS,
})(PersonalizedAutomationUpsellComponent);
